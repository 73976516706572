import React from 'react';
import PropTypes from 'prop-types';
import { Nav as SecondaryNav } from 'cccisd-laravel-appdefs';
import { Footer } from 'cccisd-laravel-boilerplate';
import style from './style.css';
import Header from '../../components/Header';
const AppDefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

export default class MainLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        showSecondaryNav: PropTypes.bool,
    };

    static defaultProps = {
        className: 'container',
        showSecondaryNav: true,
    };

    render() {
        return (
            <div>
                <Header className={`${this.props.className}`} />
                <div className={style.wrapper}>
                    <div className={style.body}>
                        {this.props.showSecondaryNav && Fortress.auth() && (
                            <SecondaryNav
                                className={this.props.className}
                                navs={AppDefs.navs}
                                routes={AppDefs.routes}
                            />
                        )}
                        <div className={this.props.className}>{this.props.children}</div>
                    </div>

                    <div className={style.footer}>
                        <Footer className={this.props.className} />
                    </div>
                </div>
            </div>
        );
    }
}
