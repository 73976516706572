import React, { useState, useEffect } from 'react';
import SelectList from 'react-widgets/lib/SelectList';
import PropTypes from 'prop-types';
import DetectClickLocation from 'cccisd-detect-click-location';
import Button from 'cccisd-click-button';
import style from './style.css';
import { connect, useDispatch } from 'react-redux';
import { updateDeploymentCycles } from 'js/reducers/report';

const CycleSelect = props => {
    const [selected, setSelected] = useState([]);
    const [visible, setVisible] = useState(false);
    const [options, setOptions] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        setOptions(props.deploymentCycles);
        setSelected(props.deploymentCycles.map(item => item.value));
        props.form.setFieldValue('cycles', props.deploymentCycles);
    }, [props.deploymentCycles]);

    const handleChange = async value => {
        const values = value.map(item => item.value);

        setSelected(values);
        await dispatch(updateDeploymentCycles(values));
        props.form.setFieldValue('cycles', value);
    };

    const selectAll = () => {
        props.form.setFieldValue('cycles', options);
        const values = options.map(item => item.value);
        setSelected(values);
    };

    const selectNone = () => {
        props.form.setFieldValue('cycles', []);
        setSelected([]);
    };

    return (
        <DetectClickLocation clickedOutside={() => setVisible(false)}>
            <Button
                title={`Select Plans (${selected.length})`}
                className="btn btn-default"
                onClick={() => setVisible(!visible)}
                style={{ display: 'block', marginBottom: '15px' }}
            />
            {visible && (
                <div
                    style={{
                        position: 'absolute',
                        zIndex: 2,
                        marginTop: '-8px',
                        marginLeft: '8px',
                    }}
                >
                    <Button
                        className={`btn btn-xs btn-default ${style.marginRight}`}
                        isDisabled={selected.length === 6}
                        onClick={selectAll}
                        title="Select All"
                    />
                    <Button
                        className="btn btn-xs btn-default"
                        isDisabled={selected.length === 0}
                        onClick={selectNone}
                        title="Deselect All"
                    />
                </div>
            )}
            <SelectList
                multiple
                data={options}
                textField="label"
                valueField="value"
                onChange={handleChange}
                value={selected}
                style={{
                    display: visible ? 'block' : 'none',
                    height: 'auto',
                    marginTop: '-15px',
                    position: 'absolute',
                    zIndex: 1,
                    paddingTop: '30px',
                }}
            />
        </DetectClickLocation>
    );
};

CycleSelect.propTypes = {
    form: PropTypes.object,
    field: PropTypes.object,
    // from redux
    deploymentCycles: PropTypes.array,
};
const mapStateToProps = state => ({
    deploymentCycles: state.app.report.deploymentCycles,
});

export default connect(mapStateToProps, {})(CycleSelect);
