import React, { useState, useEffect } from 'react';
import { DataBrowser } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import { client } from 'cccisd-apollo';
import sitesQuery from './sites.graphql';
import deploymentsQuery from './deployments.graphql';

const Fortress = window.cccisd.fortress;

const ViewData = props => {
    const [loading, setLoading] = useState(true);
    const [deployments, setDeployments] = useState([]);

    useEffect(() => {
        if (loading) {
            getDeployments();
        }
    }, []);

    const getDeployments = async () => {
        const response = await client.query({
            query: sitesQuery,
            variables: {},
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        });

        const filterObj = {
            AND: [],
        };

        //  build the filter using the sites
        if (response.data.groups.siteList.length > 0) {
            filterObj.AND.push({
                OR: response.data.groups.siteList.map(site => {
                    return {
                        contains: {
                            field: 'deploymentHandle',
                            string: `${site.group.groupId}_`,
                        },
                    };
                }),
            });
        } else {
            filterObj.AND.push({ eq: { field: 'deploymentHandle', string: '0_' } });
        }

        // get all the deployments for each site
        const dresponse = await client.query({
            query: deploymentsQuery,
            variables: {
                siteFilter: filterObj,
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        });

        setDeployments(dresponse.data.flows.deploymentList.map(d => d.deploymentId));
        setLoading(false);
    };

    if (loading) {
        return <Loader loading />;
    }

    return (
        <div className="container-fluid">
            <DataBrowser key={Fortress.user.acting.id} projectId={1} showDeploymentIds={deployments} />
        </div>
    );
};

export default ViewData;
