import React from 'react';
import { Formik, Form, Field, CccisdInput, CccisdDatepicker } from 'cccisd-formik';
import axios from 'cccisd-axios';
import notification from 'cccisd-notification';
import moment from 'moment';
import style from './style.css';
const Boilerplate = window.cccisd.boilerplate;

const DeploymentEdit = props => {
    const { closeModal, row, isClosed } = props;

    const applyTimezoneOffset = date => {
        // Our server uses UTC time so we need to offset the start/end date entered by client
        return moment(date).utc().format('YYYY-MM-DD HH:mm:ss');
    };

    const isInPast = date => {
        if (moment(date).isBefore(moment())) {
            return true;
        }
        return false;
    };

    const getDateObj = dateString => {
        return new Date(dateString.replace(' ', 'T'));
    };

    const onSubmit = async values => {
        try {
            const settings = {
                closedMessage: row['settings.closedMessage'],
                closedRoster: row['settings.closedRoster'],
                completedMessage: row['settings.completedMessage'],
                groupId: row['settings.groupId'],
                groupLabel: row['settings.groupLabel'],
                options: row['settings.options'],
                otherAllowedRoles: row['settings.otherAllowedRoles'],
                targetResponses:
                    values.targetResponses === 0 || values.targetResponses > 0
                        ? values.targetResponses
                        : row['settings.targetResponses'],
            };

            const params = isClosed
                ? {
                      label: values.planName,
                      opensAt: applyTimezoneOffset(getDateObj(values.openDate)),
                      closesAt: applyTimezoneOffset(getDateObj(values.closeDate)),
                      settings,
                  }
                : {
                      label: values.planName,
                      closesAt: applyTimezoneOffset(getDateObj(values.closeDate)),
                      settings,
                  };

            const response = await axios.put(
                Boilerplate.route('api.assignmentDeployment.update', {
                    deploymentId: row.deploymentId,
                }),
                params
            );

            if (response?.data?.errors) {
                let error = response.data.errors;
                if (error?.group) {
                    notification({ message: error.group[0], type: 'danger' });
                }
            }

            if (response?.data?.status === 'success') {
                notification({ message: 'Plan successfully updated', type: 'success' });
            }

            closeModal();
        } catch (e) {
            console.error(e);
        }
    };

    const validate = values => {
        try {
            const errors = {};
            if (!values.closeDate) {
                errors.closeDate = 'Close date is required';
            }
            if (values.closeDate) {
                if (isInPast(getDateObj(values.closeDate))) {
                    errors.closeDate = 'Close date must be in the future';
                }
            }
            if (!values.planName) {
                errors.planName = 'Plan Name is required';
            }
            if (isClosed) {
                if (!values.openDate) {
                    errors.openDate = 'Open date is required';
                }
                if (values.openDate) {
                    if (isInPast(getDateObj(values.openDate))) {
                        errors.openDate = 'Open date must be in the future';
                    }
                }
            }

            return errors;
        } catch (e) {
            console.error(e);
        }
    };

    const getInitialValues = () => {
        if (isClosed) {
            return {
                planName: row.label,
                openDate: row.opensAt,
                closeDate: row.closesAt,
                targetResponses: row['settings.targetResponses'] ? row['settings.targetResponses'] : '',
            };
        }
        return {
            planName: row.label,
            closeDate: row.closesAt,
            targetResponses: row['settings.targetResponses'] ? row['settings.targetResponses'] : '',
        };
    };

    return (
        <Formik
            onSubmit={onSubmit}
            validate={validate}
            initialValues={getInitialValues()}
            render={() => (
                <Form>
                    <Field name="planName" component={CccisdInput} label="Name" />
                    <div className={style.flex}>
                        {isClosed && (
                            <Field name="openDate" component={CccisdDatepicker} label="Open Date" showTimeSelect />
                        )}

                        <Field name="closeDate" component={CccisdDatepicker} label="Close Date" showTimeSelect />
                    </div>
                    <Field name="targetResponses" component={CccisdInput} label="Target Responses" type="number" />
                    <div className={style.buttonRow}>
                        <button className="btn btn-default" type="button" onClick={() => closeModal()}>
                            Cancel
                        </button>
                        <button className="btn btn-primary" type="submit">
                            Submit
                        </button>
                    </div>
                </Form>
            )}
        />
    );
};
export default DeploymentEdit;
