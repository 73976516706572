import React from 'react';
import Table from 'cccisd-graphql-table';
import deploymentQuery from './deploymentCyclesOrg.graphql';
import DeploymentEdit from '../DeploymentEdit';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';
import copy from 'copy-to-clipboard';
import notification from 'cccisd-notification';
import classnames from 'classnames';
import IconLink from 'cccisd-icons/link';
import IconBell from 'cccisd-icons/bell2';
import IconEdit from 'cccisd-icons/pencil5';
import IconCheckmark from 'cccisd-icons/checkmark';
import IconCross from 'cccisd-icons/cross4';
import moment from 'moment';
import style from './style.css';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

export default class DashboardTable extends React.Component {
    topTable = React.createRef();
    bottomTable = React.createRef();
    dateModal = React.createRef();
    copyModal = React.createRef();

    copyText = surveyUrl => {
        copy(Boilerplate.url(surveyUrl));
        notification({
            message: 'Survey URL copied successfully!',
            type: 'success',
            duration: 8000,
        });
    };

    loadData = () => {
        this.topTable.current.loadData();
        this.bottomTable.current.loadData();
    };

    closeDateModal = () => {
        this.dateModal.current.close();
    };

    closeCopyModal = () => {
        this.copyModal.current.close();
    };

    getSurveyUrl = row => {
        let surveyUrl = `/d/${row.hash}`;

        if (row['settings.groupId']) {
            surveyUrl = `/e2s/${row.hash}/${row['settings.groupId']}`;
        }

        return (
            <Modal
                ref={this.copyModal}
                size="medium"
                trigger={
                    <Tooltip title="Copy Survey URL">
                        <button type="button" className={classnames('btn', 'btn-success')}>
                            <IconLink />
                        </button>
                    </Tooltip>
                }
                title="Copy Survey URL"
            >
                <div>
                    <div>
                        <span>{Boilerplate.url(surveyUrl)}</span>
                    </div>
                    <br />
                    <div>
                        <button
                            type="button"
                            onClick={() => {
                                this.copyText(surveyUrl);
                                this.closeCopyModal();
                            }}
                            className={classnames('btn', 'btn-warning')}
                        >
                            <span>Copy URL</span>
                        </button>
                    </div>
                </div>
            </Modal>
        );
    };

    getOrgName = () => {
        if (this.props.isUber) {
            return this.props.selectOptions?.guList?.find(item => item.value === this.props.groupingUnitId).label;
        }
        return Fortress.user.acting.group.label;
    };

    getOpenColumns = () => {
        const openColumns = [
            {
                handle: 'actions',
                label: 'Actions',
                name: 'actions',
                hideInCsv: true,
                render: ({ row }) => {
                    const now = moment();
                    return (
                        <div>
                            {this.getSurveyUrl(row)}
                            {now.isBefore(row.closesAt) && row.isOpen && (
                                <Modal
                                    ref={this.dateModal}
                                    size="small"
                                    trigger={
                                        <Tooltip title="Edit Plan Details">
                                            <button type="button" className={`btn btn-warning ${style.spaceLeft}`}>
                                                <IconEdit />
                                            </button>
                                        </Tooltip>
                                    }
                                    title={`Edit Plan - ${row.label}`}
                                    afterClose={() => this.loadData()}
                                >
                                    <DeploymentEdit row={row} closeModal={this.closeDateModal} />
                                </Modal>
                            )}
                        </div>
                    );
                },
            },
            {
                handle: 'settings.groupLabel',
                label: 'School Name',
                name: 'settings.groupLabel',
                className: 'text-center',
                sort: true,
                filter: true,
            },
            {
                handle: 'deploymentName',
                label: 'Plan Name',
                name: 'label',
                className: 'text-center',
                sort: true,
                filter: true,
            },
            {
                handle: 'assignment',
                label: 'Plan Details',
                name: 'assignment',
                render: ({ row }) => {
                    return (
                        <div>
                            <p>{row['assignment.label']}</p>
                            <p style={{ fontSize: '14px' }}>{row['assignment.description']}</p>
                        </div>
                    );
                },
            },
            {
                handle: 'openDate',
                label: 'Open Date',
                name: 'opensAt',
                sort: true,
                filter: true,
                filterSettings: {
                    type: 'date',
                },
            },
            {
                handle: 'closeDate',
                label: 'Close Date',
                name: 'closesAt',
                sort: true,
                filter: true,
                filterSettings: {
                    type: 'date',
                },
            },
            {
                handle: 'responses',
                label: 'Completion Rate',
                name: 'responses',
                hideInCsv: true,
                render: ({ row }) => {
                    const targetReached =
                        row['settings.targetResponses'] && row['settings.targetResponses'] === row.completedResponses;

                    return (
                        <div>
                            <p>{`${row.completedResponses} out of ${row.totalResponses}`}</p>
                            {row['settings.targetResponses'] && targetReached && (
                                <div className={style.iconCheckmark}>
                                    <Tooltip
                                        title={`target responses met: ${row['settings.targetResponses']}`}
                                        placement="right"
                                        type="default"
                                    >
                                        <IconCheckmark />
                                    </Tooltip>
                                </div>
                            )}

                            {row['settings.targetResponses'] && !targetReached && (
                                <div className={style.iconCross}>
                                    <Tooltip
                                        title={`target responses not met: ${row['settings.targetResponses']}`}
                                        placement="right"
                                        type="default"
                                    >
                                        <IconCross />
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    );
                },
            },
            {
                handle: 'completedResponses',
                label: 'Completed Responses',
                name: 'completedResponses',
                hideInTable: true,
            },
            {
                handle: 'totalResponses',
                label: 'Total Responses',
                name: 'totalResponses',
                hideInTable: true,
            },
        ];

        openColumns.splice(1, 0, {
            handle: 'deploymentId',
            label: 'Org Name',
            name: 'deploymentId',
            className: 'text-center',
            hideInTable: true,
            render: () => {
                const name = this.getOrgName();
                return name;
            },
        });

        return openColumns;
    };

    getClosedColumns = () => {
        const closedColumns = [
            {
                handle: 'actions',
                label: 'Actions',
                name: 'actions',
                hideInCsv: true,
                render: ({ row }) => {
                    const now = moment();
                    return (
                        <div>
                            {now.isBefore(row.opensAt) && (
                                <Modal
                                    ref={this.dateModal}
                                    size="medium"
                                    trigger={
                                        <Tooltip title="Edit Plan Details">
                                            <button type="button" className={`btn btn-warning ${style.spaceLeft}`}>
                                                <IconEdit />
                                            </button>
                                        </Tooltip>
                                    }
                                    title={`Edit Plan - ${row.label}`}
                                    afterClose={() => this.loadData()}
                                >
                                    <DeploymentEdit row={row} closeModal={this.closeDateModal} isClosed />
                                </Modal>
                            )}
                        </div>
                    );
                },
            },
            {
                handle: 'settings.groupLabel',
                label: 'School Name',
                name: 'settings.groupLabel',
                className: 'text-center',
                sort: true,
                filter: true,
            },
            {
                handle: 'deploymentName',
                label: 'Plan Name',
                name: 'label',
                className: 'text-center',
                sort: true,
                filter: true,
            },
            {
                handle: 'assignment',
                label: 'Plan Details',
                name: 'assignment',
                render: ({ row }) => {
                    return (
                        <div>
                            <p>{row['assignment.label']}</p>
                            <p style={{ fontSize: '14px' }}>{row['assignment.description']}</p>
                        </div>
                    );
                },
            },
            {
                handle: 'openDate',
                label: 'Open Date',
                name: 'opensAt',
                sort: true,
                filter: true,
                filterSettings: {
                    type: 'date',
                },
                render: ({ row }) => {
                    const now = moment();
                    return (
                        <div>
                            <p>{row.opensAt}</p>
                            {now.isBefore(row.opensAt) && !row.isOpen && (
                                <div className={style.iconBell}>
                                    <Tooltip title="Not yet open." placement="right" type="default">
                                        <IconBell />
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    );
                },
            },
            {
                handle: 'closeDate',
                label: 'Close Date',
                name: 'closesAt',
                sort: true,
                filter: true,
                filterSettings: {
                    type: 'date',
                },
            },
        ];

        closedColumns.splice(1, 0, {
            handle: 'deploymentId',
            label: 'Org Name',
            name: 'deploymentId',
            className: 'text-center',
            hideInTable: true,
            render: () => {
                const name = this.getOrgName();
                return name;
            },
        });

        return closedColumns;
    };

    getGqlFilter = (status = true) => {
        const filterObj = {
            AND: [],
        };

        filterObj.AND.push({ eq: { field: 'isOpen', boolean: status } });

        // get the site list for the groupingUnit
        const gu = this.props.selectOptions.guList.find(item => item.value === this.props.groupingUnitId);

        // get all the deployments for each site
        if (gu.sites.length > 0) {
            filterObj.AND.push({
                OR: gu.sites.map(site => {
                    return {
                        contains: {
                            field: 'deploymentHandle',
                            string: `${site.group.groupId}_`,
                        },
                    };
                }),
            });
        } else {
            filterObj.AND.push({ eq: { field: 'deploymentHandle', string: '0_' } });
        }

        return filterObj;
    };

    render() {
        return (
            <div>
                <Table
                    csvFilename="open_deployments"
                    ref={this.topTable}
                    rowKey="deploymentId"
                    query={deploymentQuery}
                    graphqlVariables={{
                        siteFilter: this.getGqlFilter(true),
                    }}
                    columns={this.getOpenColumns()}
                    orderBy="opensAt"
                    isAscentOrder={false}
                />
                <h3>Closed Plans</h3>
                <Table
                    csvFilename="closed_deployments"
                    ref={this.bottomTable}
                    rowKey="deploymentId"
                    query={deploymentQuery}
                    graphqlVariables={{
                        siteFilter: this.getGqlFilter(false),
                    }}
                    columns={this.getClosedColumns()}
                    orderBy="closesAt"
                    isAscentOrder={false}
                />
            </div>
        );
    }
}
