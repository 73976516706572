import React, { useState } from 'react';
import Modal from 'cccisd-modal';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import style from './style.css';

const Fortress = window.cccisd.fortress;
const AppDefs = window.cccisd.appDefs;

const SelectAssessmentForm = ({ onPlanSelection, form }) => {
    const [selectedAssessment, setSelectedAssessment] = useState(null);

    const assessmentsInfo = AppDefs.app.assessments;

    const renderPreviewButton = (assessment, level) => {
        return (
            <div>
                <Modal
                    trigger={
                        <button type="button" className={`${style.button} btn`}>
                            {level} Preview
                        </button>
                    }
                    title={`${level} Preview`}
                    size="large"
                    contentStyle={{ width: '1200px' }}
                    headerStyle={{
                        backgroundColor: '#d68f28',
                        color: '#fff',
                    }}
                    bodyStyle={{ backgroundColor: '#edf2f7' }}
                >
                    <div>
                        <DeploymentPlayer
                            deploymentHandle={assessment[level]}
                            pawnId={Fortress.user.acting.id}
                            pawnHash={Fortress.user.acting.random_hash}
                            disableLayout
                            isPreviewMode
                        />
                    </div>
                </Modal>
            </div>
        );
    };

    const renderSelectButton = (assessment, assessmentIndex) => {
        const isSelected = selectedAssessment === assessmentIndex;
        // console.log({ isSelected, selectedAssessment, assessmentIndex });

        // if (isSelected) {
        //     onPlanSelection(assessmentIndex);
        // }

        const buttonData = {
            select: { className: 'btn-primary', text: 'Select this plan' },
            selected: { className: style.grayButton, text: 'Selected' },
        };

        let buttonInfo = isSelected ? buttonData.selected : buttonData.select;

        // const handleClick = () => {
        //     // console.log('plan select button', { isSelected, assessment, selectedAssessment });

        //     setSelectedAssessment(isSelected ? null : assessmentIndex);
        // };

        return (
            <button
                type="button"
                className={`'btn btn-block' ${buttonInfo.className}`}
                onClick={() => {
                    setSelectedAssessment(isSelected ? null : assessmentIndex);
                    onPlanSelection(isSelected ? null : assessmentIndex);
                    form.setFieldTouched('assessmentPlan', true);
                }}
            >
                {buttonInfo.text}
            </button>
        );
    };

    return (
        <div className={style.assessmentContainer}>
            {assessmentsInfo.map((assessment, index) => {
                return (
                    <div key={assessment.index} className={style.formBox}>
                        <h3 className={style.title}>{assessment.title}</h3>
                        <div className={style.description}>{assessment.description}</div>

                        <div className={style.buttonContainer}>
                            {renderPreviewButton(assessment, 'HS')}
                            {renderPreviewButton(assessment, 'MS')}
                        </div>
                        <div className={style.buttonContainer}>{renderSelectButton(assessment, index)}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default SelectAssessmentForm;
