import React from 'react';
import { Route, NotFound } from 'cccisd-laravel-boilerplate';
import defaultProps from 'recompose/defaultProps.js';
import { Switch } from 'react-router-dom';
import { AdminRoutes, Manage as AppdefManage, Dashboard as AppdefDashboard } from 'cccisd-laravel-appdefs';
import { AuthRedirect } from 'cccisd-laravel-nexus';
import { Quest } from 'cccisd-laravel-assignment';
import { ResourceCenter, ResourceManager } from 'cccisd-laravel-resources';
// Layouts
import MainLayout from './layouts/MainLayout';
import QuestLayout from './layouts/QuestLayout';

// Pages
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import Welcome from './pages/Welcome'; // Include it into main bundle
import Assessment from './pages/Assessment'; // Include it into main bundle
import YouthAssessment from './pages/YouthAssessment'; // Include it into main bundle
import Dashboard from './pages/Dashboard';
import AdminCenter from './pages/AdminCenter';
import AccountActivate from './pages/AccountActivate';
import ViewData from './pages/ViewData';

// Laravel packages
import Nexus from 'bundle-loader?lazy!cccisd-laravel-nexus';

// Bind MainLayout by default
const AppRoute = defaultProps({ layout: MainLayout })(Route);

const MainLayoutFluid = defaultProps({ className: 'container-fluid' })(MainLayout);
const MainLayoutSecondaryNav = defaultProps({ showSecondaryNav: true })(MainLayout);
const MainLayoutFluidSecondaryNav = defaultProps({
    className: 'container-fluid',
    showSecondaryNav: true,
})(MainLayout);

let Fortress = window.cccisd.fortress;
let AppDefinitions = window.cccisd.appDefs;

const componentMatch = {
    AccountActivate,
    AdminCenter,
    AppdefManage,
    AppdefDashboard,
    Dashboard,
    ResourceCenter,
    ResourceManager,
    Welcome,
    YouthAssessment,
    ViewData,
};

const layoutMatch = {
    MainLayout,
    MainLayoutFluid,
    MainLayoutSecondaryNav,
    MainLayoutFluidSecondaryNav,
};

export default () => (
    <Switch>
        {AppDefinitions.routes.map(route => {
            const Component = componentMatch[route.componentHandle];
            if (!Component) {
                return null;
            }

            return (
                <AppRoute
                    key={route.handle}
                    path={route.url}
                    component={Component}
                    componentProps={route.componentProps}
                    exact
                    layout={
                        'layout' in route && layoutMatch[route.layout] ? layoutMatch[route.layout] : MainLayoutFluid
                    }
                />
            );
        })}
        <AppRoute path="/" component={Welcome} exact />
        <AppRoute path="/welcome" component={Welcome} />
        {/* {Fortress.auth() && (
            <AppRoute path="/dashboard" component={Dashboard} layout={MainLayoutFluid} />
        )} */}
        <AppRoute path="/account/activate/:activationHash?" component={AccountActivate} />
        <AppRoute path="/d/:deploymentHash/:shortHash" component={Assessment} layout={QuestLayout} />
        <AppRoute path={['/quest/preview', '/d', '/survey']} component={Quest} layout={QuestLayout} />
        <AppRoute path="/e2s/:deploymentHash/:groupId" component={YouthAssessment} layout={QuestLayout} />

        {/* Laravel packages routes */}
        {AdminRoutes(MainLayoutFluid)}

        <AppRoute path="/account" component={Nexus} />

        <AppRoute path={Fortress.settings.config.after_login_url} component={AuthRedirect} exact />

        {/* Not found page */}
        <AppRoute component={NotFound} />
    </Switch>
);
