import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import { DeploymentEvaluator } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import groupQuery from './groupQuery.graphql';

const YouthAssessment = props => {
    const { match } = props;
    const [list, setList] = useState(null);
    const [loading, setLoading] = useState(true);

    const [show404, setShow404] = useState(null);

    useEffect(() => {
        let { groupId } = match && match.params;
        async function fetchData() {
            try {
                const res = await apollo.query({
                    query: groupQuery,
                    fetchPolicy: 'network-only',
                    variables: { groupId },
                });

                const groupList = getGroupList(res.data);
                setList(groupList);

                setLoading(false);
            } catch (error) {
                console.error(error);
                setShow404(true);
            }
        }
        fetchData();
    }, []);

    const getGroupList = data => {
        const {
            groups: { classList },
        } = data;

        return classList.map(item => {
            const siteLabel = item.parentGroup.site.group.label;
            const id = item.group.groupId;
            const name = `${siteLabel} - ${item.group.label}`;
            return {
                id,
                name,
            };
        });
    };
    if (show404) {
        return (
            <div className="not-found">
                <h2>404 ERROR</h2>
                <div style={{ marginBottom: '1em' }}>
                    Sorry, an error has occurred. Requested page not found!
                </div>
            </div>
        );
    }
    if (loading) {
        return <Loader loading />;
    }
    if (list) {
        const componentProps = {
            ...props,
            flowProps: {
                hidePlayButton: true,
                variables: {
                    groupList: list,
                },
            },
        };

        return (
            <>
                <DeploymentEvaluator {...componentProps} />
            </>
        );
    }
    return null;
};

YouthAssessment.propTypes = {
    match: PropTypes.object,
};

export default YouthAssessment;
